<template>
  <div>
    <div class="FaBox" v-if="Types==1?true:false">
      <div class="flex">
        <div class="addClass">
          <p>添加年级</p>
          <input type="text" placeholder="年级名称" class="inp" v-model="grade"/>
          <button class="add" @click="AddClass">添加</button>
        </div>
        <div class="MyClass">
          <p>我的年级</p>
          <!-- <p style="display:inline" v-if="item.isMarked?true:false"> - </p> -->
          <div class="myclass_div">
              <ul>
                  <li v-for="item in list" :key="item.ID" class="mycalss">
                      {{item.TypeName}}
                  </li>
                  <li v-for="(data,index) in NewList" :key="index + '^'" class="mycalss">
                      {{data}} -
                    <button @click="handleDelClick(index)" class="del">x</button>
                  </li>
              </ul>
          </div>
        </div>
      </div>
      <button class="btn" @click="Save">保存</button>
    </div>
<!-- ****************************************************************************** -->
    <div class="FaBoxs" v-if="Types==1?false:true">
      <div class="flexs">
        <div class="addClasss">
          <p>添加年级</p>
            <div class="Addmyclass">
              <ul>
                  <li v-for="(item,idx) in Class_I" :key="item.ID" @click="active(item,idx)" class="Classitem" :class="idx==index?'hover':''">
                      {{item.Name}}
                  </li>
              </ul>
          </div>
        </div>
          <button class="adds" @click="AddClasss">添加</button>
        <div class="MyClasss">
          <p>我的年级</p>
          <div class="myclass_divs">
              <ul>
                  <li v-for="item in list" :key="item.ID" class="mycalss">
                      {{item.TypeName}}
                  </li>
                  <li v-for="(data,index) in NewClassList" :key="index + '^'" class="mycalss">
                      {{data}} -
                    <button @click="Clicks(index)" class="dels">x</button>
                  </li>
              </ul>
          </div>
        </div>
      </div>
      <button class="btns" @click="Savess">保存</button>
    </div>
  </div>
</template>
<script>
import { classTypeInfo, typeAdd } from '@/api/user.js'
export default {
  data () {
    return {
      list: [], // 原有年级
      NewList: [], // 新建年级
      grade: '', // 年级名称
      Types: '', // 登录的type类型
      NewClassList: [],
      index: Number,
      ClaName: '', // 点击的年级名字
      Class_I: [
        {
          ID: 1,
          Name: '一年级'
        },
        {
          ID: 2,
          Name: '二年级'
        },
        {
          ID: 3,
          Name: '三年级'
        },
        {
          ID: 4,
          Name: '四年级'
        },
        {
          ID: 5,
          Name: '五年级'
        },
        {
          ID: 6,
          Name: '六年级'
        },
        {
          ID: 7,
          Name: '七年级'
        },
        {
          ID: 8,
          Name: '八年级'
        }
      ]
    }
  },
  mounted () {
    const infos = JSON.parse(localStorage.getItem('userInfo'))
    this.Types = infos.Type
    //   console.log(infos.Type)
    this.classTypeInfo()
  },
  methods: {
    active (item, idx) { // 除幼儿园以外的点击事件
      this.index = idx
      this.ClaName = item.Name
      // console.log(item.Name);
      // console.log(idx);
    },
    AddClasss () { // 除幼儿园以外的添加事件
      const items = this.list.find(item => {
        return item.TypeName == this.ClaName
      })
      const Newitem = this.NewClassList.find(items => {
        return items == this.ClaName
      })
      if (items == undefined && Newitem == undefined && this.ClaName != '') {
        this.NewClassList.push(this.ClaName)
      } else if (this.ClaName == '') {
        this.$message.error('年级不能为空')
      } else {
        this.$message.error('请勿重复添加')
      }
      // console.log(Newitem);
      // console.log(items);
    },
    AddClass () { // 点击添加班级
      //  this.NewList.push({
      //       Newgrade:this.grade,
      // //     });
      const items = this.list.find(item => {
        return item.TypeName == this.grade
      })
      const Newitem = this.NewList.find(items => {
        return items == this.grade
      })
      if (items == undefined && Newitem == undefined && this.grade != '') {
        this.NewList.push(this.grade)
      } else if (this.grade == '') {
        this.$message.error('年级不能为空')
      } else {
        this.$message.error('请勿重复添加')
      }
      // console.log(items);
      // console.log(Newitem);
      this.grade = ''
    },
    handleDelClick (index) { // 点击删除未保存的班级
      this.NewList.splice(index, 1)
    },
    Save () { // 点击保存
      const A = this.NewList.join('#')
      const data = {
        typeName: A
      }
      // console.log(A);
      typeAdd(data).then(res => {
        if (res.status.code == 1) {
          this.NewList = [] // 清空后续添加的年级
          this.classTypeInfo() // 刷新接口
          this.$message({
            message: '添加成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    // ***************************************
    Clicks (index) { // 点击删除未保存的年级
      this.NewClassList.splice(index, 1)
    },
    Savess () { // 点击保存
      // console.log(this.NewClassList);
      const A = this.NewClassList.join('#')
      const data = {
        typeName: A
      }
      typeAdd(data).then(res => {
        if (res.status.code == 1) {
          this.NewClassList = [] // 清空后续添加的年级
          this.classTypeInfo() // 刷新接口
          this.$message({
            message: '添加成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    classTypeInfo () { // 年级信息
      classTypeInfo().then(res => {
        // console.log(res)
        this.list = res.data
      }).catch(err => { console.log(err) })
    }
  }
}
</script>

<style lang="scss" scoped>
.FaBox {
  width: 700px;
  height: 500px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #ccc;
  .flex{
      width: 600px;
      display: flex;
      justify-content: space-around;
  }
  .addClass {
    width: 200px;
    height: 230px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .inp{
        width: 200px;
    }
    .add{
        width: 80px;
        margin-top: 20px;
    }
  }
  .MyClass {
    display: flex;
    flex-direction: column;
    .myclass_div{
        display: flex;
        width: 230px;
        height: 300px;
        overflow: auto;
        border: 1px solid #ccc;
        li{
            width: 150px;
            list-style: none;
        }
        .del{
            border: none;
        }
    }
  }
}
.btn{
    width: 200px;
    height: 30px;
    margin-top: 20px;
}
// ****************************************************
.FaBoxs {
  width: 700px;
  height: 500px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #ccc;
  .flexs{
      width: 600px;
      display: flex;
      justify-content: space-around;
  }
   .adds{
        width: 50px;
        height: 30px;
        align-self: center;
        margin-right: 20px;
        // margin-top: 20px;
    }
  .addClasss {
    width: 300px;
    // height: 230px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .Addmyclass{
        display: flex;
        width: 230px;
        height: 300px;
        overflow: auto;
        border: 1px solid #ccc;
          li{
            width: 150px;
            list-style: none;
        }
    }
  }
  .MyClasss {
    display: flex;
    flex-direction: column;
    .myclass_divs{
        display: flex;
        width: 230px;
        height: 300px;
        overflow: auto;
        border: 1px solid #ccc;
        li{
            width: 150px;
            list-style: none;
        }
        .dels{
            border: none;
        }
    }
  }
}
.btns{
    width: 200px;
    height: 30px;
    margin-top: 20px;
}
.mycalss{
  padding-top: 8px;
}
.Classitem{
  padding-top: 10px;
}
.Classitem.hover{
  color: red;
}
.Classitem:hover{
  cursor: pointer;
}
</style>
